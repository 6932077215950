import React from 'react'
import { graphql, Link } from 'gatsby'
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import { ColumnSection } from '../components/sections/column-section'
import { ContactSection } from '../components/sections/contact-section'
import { TrainingCarouselSection } from '../components/sections/training-carousel-section'
import { FaqSection } from '../components/sections/faq-section'

export const query = graphql`
  query PlainPageQuery($id: String) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    },
    page: sanityPlainPage(id: { eq: $id }) {
        id
        slug {
          current
        }
        showBanner
        seoTitle
        seoKeywords
        seoDescription
        hideOverlay
        bannerTitle
        bannerSubTitle
        bannerImage {
          asset {
            url
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        bannerCta {
          label
          url
        }
        _rawBody
    }
  }
`

const PlainPage = ({ data = {}, errors }) => {

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const site = data.site || {}
    const page = data.page || {}

    const { _rawBody } = page


    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    return (
        <Layout page={page} >
            <Container>
                {_rawBody && _rawBody.length > 0 && <section className="py-16 px-4 4xl:px-0 bg-white">
                    <div className="max-w-6xl mx-auto px-4 portable-text">
                        <PortableText blocks={_rawBody} />
                    </div>
                </section>}
                <FaqSection />
                <ContactSection />
            </Container>
        </Layout>
    )
}

export default PlainPage
